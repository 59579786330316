// We have these default feature settings, and we're listening to them
// from Firestore.  We also put them into `window` in the `play` function,
// and we save them in local storage.  The reason we don't completely
// rely on the `play` function is that iOS will cache the results of `play`
// until we redeploy, so we couldn't change the feature settings unless
// we deploy again.

import { experimentDefaultValues } from "./experiments";

// NOTE: Don't change the type of any of these, or our local storage saving will break.
// You can add or remove, but do not change any type.
export const defaultFeatureSettings = {
    ...experimentDefaultValues,

    // API
    apiV2RoutesOnly: false,

    // SQL
    linkSQLFileTrackingTableToApps: false,
    sqlRediscoverOnReload: false,
    sqlContinuations: false,
    sqlHideSystemTables: false,
    sqlShowDetailedErrorMessageInPlayer: false,
    sqlEnforceRowLimit: false,
    sqlLinkSpecialTablesWhenAddingPlugin: false,

    // GBT
    gbtFileTrackingInTransactions: false,
    gbtFileTrackingCronJob: false,
    gbtRepackCronJob: false,
    // NOTE: This is on by default so that tests run with it on
    gbtSoftDeleteRows: true,
    gbtRowDeletionCronJob: false,
    // NOTE: This is on by default so that tests run with it on
    gbtContinuationsWithAppUserID: true,
    // If this is set then we will first try to query the async replica GBT
    // database, and only if it doesn't have the minimum version we need will
    // we fall back to the primary.
    gbtQueryReplica: true,
    bigTablesMaintenanceCronEnabled: false,
    // This will make it so queries to Big Tables only return the first 10
    // rows if the eminence is not set.  We'll turn this on after the beta.
    enforceBigTablesEminence: false,
    gbtCreateIndexesFromRequests: false,
    // NOTE: This is on by default so that tests run with it on
    gbtAsNumberV2: true,
    // NOTE: This is on by default so that tests run with it on
    gbtCanonicalArrayOverlap: true,
    gbtIndexRequestDurationThreshold: false,
    gbtNewTableInSmallestShard: false,
    gbtLiveUpdates: false,
    gbtLiveUpdateRowVersionQueries: true,
    // NOTE: This is on by default so that tests run with it on
    gbtRetryFailedReplicaQueries: true,
    gbtFileTrackingNonDirectConnection: false,
    glideTablesOnGBT: true,
    gbtUserProfileTables: false, // Only controls Big Tables, NOT Glide Tables in GBT data store
    newGlideTableInGBTProbability: 0,
    gbtUseTableStoreProbability: 0,
    tableStoreExperiments: false,
    // Queryables
    // This enables local query fixup for queryables.  It's not connected to
    // allowing queries on non-queryables, which is always enabled (there's no
    // feature setting for that).  The new Tables component uses that, so it's
    // well-established.
    queriesInComputationModel: false,
    allowGBTDeepLookups: false,

    // Derived values cache
    cacheDerivedValues: false,

    // Automations
    automationsPersistUnconfirmedMutations: false,
    automationsSpeedUpIdempotentSteps: false,
    automationsComputationModelTimeoutPermanent: false,
    automationsPassPostActionFailuresThrough: false,
    automationsFailEnqueueDataActionImmediately: false,
    allowTriggerAutomationAction: false,

    // PGmirror
    pgMirrorCountRowsChangedForBilling: false,
    pgMirrorFullyPreliminaryRows: false,
    checkpointPgMirrorReloads: false,
    unlimitedRowsPGMirror: false,
    pgMirrorCheckLastReloadedAt: false, // if this is enabled
    pgMirrorCheckLastReloadedAtSampleProbability: false, // then we check with this probability
    pgMirrorCheckLastAirtableBackstop100Hours: false, // probability * 100 hours, so 0.5 is 50 hours
    pgMirrorAvoidReloadIfNoChanges: false,

    // Data import
    useBetterDateInferenceOnImport: false,
    restoreDeletedRowsOnNativeTableImport: false,
    useBetterDateOnlyInferenceOnImport: false,
    importDateOnlyTimeZoneAgnostic: false,

    // Plugins
    // We want to have some features as opt in until we release pricing v4
    "pricingv4-allowTriggerWebhookPlugin": false,
    "pricingv4-allowOpenAIv2Plugin": false,
    allowCustomAIComponent: false,
    allowAIComponentSharing: false,
    allowPluginEmailTrigger: false,
    allowPluginPushNotifications: false,
    allowGlideAPIPlugin: false,
    allowPluginSlackTrigger: false,
    pluginActionWriteback: false,
    "plugin-push-notifications": false,
    pluginLearnMoreLinkToActions: false,
    proV2OnlyHasFreeTierPlugins: false,
    forceRedirectThroughDeployedInPluginOAuth: true,
    pluginEntitlements: false,
    enableBigQueryPlugin: false,
    validatePluginWithOAuthCheckFirst: false,
    passJSONAsJSONToPlugins: false,
    rehostFileTimeout: false,

    enableBillingLockoutOnFailedPayments: false,
    enableBillingUnlockOnRecoveredSubscriptions: false,
    enableBillingOrganizationLockoutEvents: false,

    // Controls the toggle of the `useBillingV4Interface` experiment. Change it appropriately in Firestore.
    allowBillingV4Interface: false,
    allowNewAppModalWorkflow: false,
    allowRenderableContentComponent: false,
    allowIFrameEmbedding: false,
    allowHumanInTheLoop: false,
    newComponentsForNewColumns: false,
    showMissingEntities: false,
    flyio: false,
    templateSubmit: false,
    reloadQuota: false,
    enforceGoogleSheetsReloadEminence: false,
    referralLinks: false,
    proxyUploadsViaSameOrigin: false,
    // We've had issues with the frontend writing incorrectly encoded strings
    // to the backend. We'll mitigate this with base64 encoding. Since this
    // is a major change, we need the ability to roll it back instantly.
    // Hence the feature setting.
    base64EncodeBuilderApp: false,
    useBuilderFunctionsProxy: true,
    useFunctionsProxy: true,
    traceImageLoadProbability: 0.05,
    traceLazyLoadingProbability: 0.05,
    cleanupFirebaseAuth: false,
    showFileQuotas: false,
    enforceFileQuotas: false,
    fileTrackingV2Subsystem: false,
    fileTrackingV2TrackUploads: false,
    fileTrackingV2AutomaticDeletion: false,
    promoCodesGloballyAvailable: false,
    userAPIFetchColumn: false,
    allowedEmailsFromUserProfile: false,
    askForBuilderLockOverride: false,
    offlineActionQueueForEverybody: false,
    firestorePersistenceInPWA: false,
    readOnlyBuilder: false,
    debugLogging: false,
    cloudinaryGlideOnly: false,
    unauthenticatedWebhooks: false,
    cleanupAppLoginTokens: true,
    manualV2toV3Enabled: false,
    v3AnnualPlans: false,
    crudCountsInAutomations: false,
    softEnforcementEnabled: false,
    hardEnforcementEnabled: false,
    paidSoftEnforcementEnabled: false,
    paidHardEnforcementEnabled: false,
    gettingStartedPromptEnabled: true,
    anonymousUserMiddleware: false,
    customCodeComponent: false,
    usePexelsStockPhotos: false,
    reportThisApp: false,
    createTemplatesDashboard: false,
    payAsYouGoAddon: false,
    // For testing/debugging on staging
    buyButtonWithoutStripe: false,
    magicLinkInPinEmail: false,
    activationChecklistProbability: 0.5,
    privateMagicLinks: false,
    skipQuotaBufferEventForV4: false,
    checkImage: false,
    curateBuilderActions: false,
    requestFullBodyInEnsureDataLiveliness: true,
    pingBaselineEndpoint: false,
    nudgeGmailSignUpWithGAuth: 0.5,

    // The constant this listener probability is used to reduce
    // is defined in packages/firestore-datastore/src/listener.ts
    firestorePollingListener: 1,

    enforceBigQueryEminence: true,
    onboardingToFullScreenNewProjectFlow: 0.5,
    tutorialAppForNewOrg: false,
    reduceSnapshotMemoryConsumption: false,
    allowRedirectToCustomDomain: false,
    processDomainsDestructiveActions: false,
    showGmailForSendEmails: false,
    webhookWriteBack: false,
    enforceSSODomainConsistency: false,
    proxyCloudStorage: false,
    v4billingSystem: false,
    customActionLogging: false,
    useGoogleIdentityServices: false,
    incrementalNativeTableSnapshots: false,
    enableDescopeCookieAuthentication: false,
    enableDescopeCookieAuthenticationLogging: false,
    enableBusinessFreeTrials: false,
    directDeleteMultipleRows: false,
    requestSignatureAction: false,
    chargeForQueryTables: false,
    enforcePublicUserQuota: false,
    disableReferralBonuses: false,
    useSendEmailLegacyQuota: true,
    useZapsAndWebhookLegacyQuota: true,
    countSendEmailActionAsUpdates: false,
    countZapActionAsUpdates: false,
    countWebhookActionAsUpdates: false,
    logRftEligibilityCheckDetails: false,
    glideExpertSelfServe: false,
    proxyMSGraphCalls: false,
    horizontalCollection: false,
    showActionStatusProgressBar: false,
    forceAppUserEmailWhenWritingOwnedRow: false,
    ignoreAppUserEmailInOwnedRowEnforcementInDataEditor: false,
    disablePublishedAppsQuota: false,
    pubSubForJobsWhenPossible: false,
    showCalendarEvenWhenEmpty: false,
    throttleMutateTables: false,
    limitMutateTables: false,
    useBetterRowCounting: false,
    emailAirtableUsersAboutAuthFailure: false,
    usePlaySpecificBundle: false,
    enqueueActionsInMutateTables: false,
    enqueueActionsInEnqueueDataAction: false,
    injectDataDogRUMTrackingCodeIntoBuilder: false,
    nativeTableOrderByEnqueuedAt: false,
    enableGenericAPIExperiment: false,
    debounceDataEditorEdits: false,
    alertOnFirestoreActionDataLoss: true,
    onlyListenToExistingActions: false,
    showSignInViaSSOOption: false,
    enableSendEmailAction: true,
    enforceSSOPlan: false,
    jdbcUseSSHuttle: false,
    newBillingLiveMode: false, // live mode being the opposite of shadow mode
    disableFrontendEncodeUploadURI: false,
    ignoreRepeatedBrokenReloads: false,
    // This is an experiment to see how much can save in costs if we do the
    // automatic refresh less frequently.
    lessFrequentAutomaticRefresh: false,
    exitProcessBuilderAppIfNoChanges: false,
    // This will only set `lastSeenAt` on builder actions if the current
    // value is older than 30 minutes.
    // https://github.com/quicktype/glide/issues/21833#issuecomment-1678264346
    debounceBuilderActionLastSeenAt: false,
    // This makes it so we use native table snapshots for
    // `DataRowStore.getRowsWhere`, vs loading everything from Firestore.
    // We've been doing that for Google Sheets tables for a long time.
    useNativeTableSnapshotsForLoadingAppData: false,
    // If this flag is enabled, we only attempt to correct private-vs-public
    // row disparities within a Google Sheets reloads if we find any data
    // "out of place". This can sometimes happen when enabling row owners:
    // the mechanism for moving data between Firestore collections can time out
    // or crash, and we have to recover from this.
    // Previously we would always attempt a recovery, but even reading
    // nonexistent data incurs a cost in Firestore, and we need to reduce these
    // costs.
    deleteIncorrectRowsPathOnlyWhenNecessary: false,
    jdbcRequireIdentityEncoding: false,
    billingV4Experiment: false,
    billingV4Entitlements: false,
    // `billingV3SubscriptionsWebhookDisabled` disables the v3 billing system logic handler for the Stripe subscriptions
    // webhook and leaves only the v4 billing system logic handler.
    billingV3SubscriptionsWebhookDisabled: false,
    // `billingV3InvoicesWebhookDisabled` disables the v3 billing system logic handler for the Stripe Invoices webhook,
    // essentially neutralizing it.
    billingV3InvoicesWebhookDisabled: false,
    // `billingV3ResetQuotasCronjobDisabled` disables the cronjob for resetting the quotas in the old billing system.
    billingV3ResetQuotasCronjobDisabled: false,
    // `billingV3SendStripeRenewalEmailCronjobDisabled` disables the cronjob for sending Stripe renewal emails in the old
    // billing system. This seems to have been used for subscriptions that were tied to apps.
    billingV3SendStripeRenewalEmailCronjobDisabled: false,
    // `billingV3SendQuotaEmailsCronjobDisabled` disables the cronjob for sending quota emails in the old billing
    // system.
    billingV3SendQuotaEmailsCronjobDisabled: false,
    // `billingV3StripeUsageUpdateCronjobDisabled` disables the cronjob for sending Stripe usage updates in the old
    // billing system.
    billingV3StripeUsageUpdateCronjobDisabled: false,
    // `billingV3SendBillingRemindersCronjobDisabled` disables the cronjob for sending billing reminders in the old
    // billing system.
    billingV3SendBillingRemindersCronjobDisabled: false,
    skipAirtableReloadAfterUpdate: false,
    deleteOldBuilderBackups: false,
    // `billingV4OffTheMenuPortalEnabled` enables the off-the-menu portal for the new billing system.
    billingV4OffTheMenuPortalEnabled: false,
    // `billingV4EnableDeliverEmailFromActionUsage` enables charging updates for the deliver email native action.
    billingV4EnableDeliverEmailFromActionUsage: false,
    extraBackoffTimeForQueueDrops: false,
    isTrackingEnforcementEvent: false,
    billingV4ForNewUsers: false,
    billingV4ReverseFreeTrialExperiment: false,
    billingV4ReverseFreeTrialChance: 0.5,
    billingV4MonthlyUsagePeriodBoundCheck: false,
    billingV4ProcessPaymentMethodStripeWebhook: false,
    billingV4PayAsYouGoByDefault: false,
    billingV4DisableAPIv2BillingMiddleware: false,
    logPubsubMessagePublishTime: false,
    searchableColumnsInPublishedApp: false,
    // TODO: make this true by default or... better yet, actually do the work to remove the flag.
    // this is fine for testing, but ends up being used before flags are loaded and there
    // doesn't appear to be a better place to put it.
    clientVersionPollingForUpdates: false,
    clientVersionPollingForUpdatesInPlayer: false,
    enforceActionTiers: false,
    enableAppModificationMetricsCleanup: false,
    enforceComponentTiers: false,
    // When `true`, `templateSellerGetsFullRevenue` makes the seller of a template in the template store get the full
    // revenue of the sale. When `false`, the seller gets 70% of the revenue and Glide gets 30%.
    templateSellerGetsFullRevenue: false,
    billingV4ResetOnV3Reset: false,
    disableHeartbeatEnforcement: false,
    disableHeartbeatInterval: false,
    allowNewBillingAndUsageScreen: false,
    enableCorsWhitelistAndAllowCredentials: false,
    enableCorsWhitelistAndAllowCredentialsLogging: false,
    billingV4NoOveragesInvoiceWhenPAYGIsDisabled: false,
    preventAddingNewAirtableAPIKeys: false,
    hideAirtableAPIKeyForNewApps: false,
    preventDuplicateExternalSources: false,
    deprecateWebhookAction: false,
    notifyActionErrors: false,
    ensurePermissionsOnOAuthUpdate: false,
    personalUsersAPI: false,
    allowManualTriggers: false,
    playerDatadogObservability: false,
    playerDatadogSampleRate: 0,
    // Removes heavy queries from the old implementation (pre billing v4) of `getQuotaStateForOrg`.
    removeHeavyQueriesFromOldQuotaStateForOrg: false,

    uploadHandlerLoopPrevention: false,

    getDataSourceWarnings: false,
    // NOTE: This is on by default so that tests run with it on
    elideEmptyPollingCallbacks: true,
    // NOTE: This is on by default so that tests run with it on
    airtableUseFormulaResultType: true,
    dontSaveSchemaWithDuplicateTables: false,
    reportBillableRunIntegrationBIEvents: false,
    // NOTE: This is on by default so that tests run with it on
    preferRowIDInStorageController: true,
    getDeployVersionFromFirestore: false,
    getBuilderDeployVersionFromFirestore: false,
    keepUTCMarkerWhenParsingDateStrings: false,
    // NOTE: This is on by default so that tests run with it on
    validateGoogleDriveOAuthToken: true,
    processIncomingActionsInCronJob: false,
    allowAIComponentSuggestedFields: false,
    syncAndRetryOnPrivateRowFailure: false,
    // NOTE: This is on by default so that tests run with it on
    omitFixingForGetAllowedEdits: true,
    allowOmitScopesOnRefresh: true,
    allowAnalyzePerformanceForUsers: true,
    shouldUsePlay2: false,
    reportShortcutCRUD: false,

    setMutatingScreenKindUndefinedForOnSubmit: true,

    reportCRUDAirtableAsExternal: false,
    reportCRUDExcelAsExternal: false,
    reportCRUDBigQueryAsExternal: false,
    reportCRUDMySQLAsExternal: false,
    reportCRUDDataPluginAsExternal: false,
    reportCRUDQueryablePluginAsExternal: false,
    reportCRUDUnknownAsExternal: false,

    pubsubOtelTracing: false,
    showAIGeneratedTemplate: false,

    disableEmailCTA: false,
    disableInAppEmailsCTA: false,

    fileTrackingReadOnlyDB: false,
    alloyDBScienceExperiments: false,

    nativeTablesAssertActionQueueCommit: false,

    aiFeaturesAndTraining: false,
    lowDeploymentVersionRefreshRate: false,

    // https://github.com/glideapps/glide/issues/30199
    enableTimeOnlyColumnComparisons: true,

    setColumnsErrorOnSkipLoading: true,
    enableRowLimitOverageToast: false,
    enableB3TracingMiddlewareForGateway: false,
    enableB3TracingMiddlewareForServices: false,

    useFloatingPointQuantities: false,

    useNewBillingUsageMetadata: false,

    makeCloudinaryCheaper: false,

    // NOTE: This is on by default so that tests run with it on
    requireDateColumnsToSendDatesInWebhooks: true,
    dontCheckForOrgsLoadedIfNoneOrg: false,
    enableCronReindexFileTrackingForBigTables: false,
    alwaysAddFallbackUserProfileRow: false,
    allowCustomWorkflowSchedule: false,
    acceptJSONInputsFromPrimitiveColumns: false,
    // NOTE: This is on by default so that tests run with it on
    writeDateOnlyAsAgnosticToAgnosticColumns: true,
    // This will forego the "app data db" database when doing queryable
    // queries, and when doing plugins.  That means only GBT queryable data
    // sources will work, and plugins will not use cached data.
    // NOTE: Do not turn this on unless the "app data" database is down!
    doNotQueryAppDataDB: false,
    loopMultiCaseDescriptionForPopulation: false,
    // We'll toggle this once we get to the public beta
    enforceAutomationEntitlements: false,
    enforceGetAutomationSchedules: false,
    // https://github.com/glideapps/glide/issues/30834
    reallyOnlyIncludeTablesUsedInApp: false,

    checkAppAccessWhenValidatingAction: false,
    useVisitBuilderActionWhenCopyingApps: false,

    // duplicating aps
    copyAppOmitOverwritingSourceMetadataWhenCopyingTables: false,
    copyAppCheckSourceMetadataBefore: false,
    copyAppCheckSourceMetadataAfter: false,
    copyAppCheckHasSourceMetadataAfter: false,
    // Search/replace the original app ID in the app
    // description/schema/actions with the copied app ID?
    // https://github.com/glideapps/glide/pull/31725
    copyAppRewriteAppIDs: false,
    jobBasedDuplicateApp: false,
    stringifyResponseOnJobBasedDuplicateApp: false,
    copyAppSecrets: false,

    useNewGetOrgUsagesUpdateQuery: false,

    useMagicBuilderInOnboarding: false,
    openAppFromWizard: true,
    replaceActionsRoutesWithWorkflows: false,
    mapboxReuseMaps: false,

    // See `builderNotes` in `experiments.ts`
    allowBuilderNotes: false,

    proxyPinEmailIcons: false,
    useBase64EncodingForAppLoginToken: false,
    dataGridConditionalColumns: false,
    limitCustomDomainSubdomains: false,
    allowStopwatchComponent: false,
    allowDataPlotComponent: false,

    // Whenever we make a new private beta feature, we create a new flag for it.
    // This is a general private beta flag that can be used to enable any private beta feature.
    enablePrivateBeta: false,

    allowClassicAppsToPages: false,
    semiStrictConvertSerializableValue: false,
    tableStoreMigrations: false,
    enableHostCheck: false,
    gbtUseTableStoreProbabilityForQuery: 0,
    gbtUseTableStoreProbabilityForQueryTableVersions: 0,
    gbtQueryTrafficUseTableStore: false,
    gbtQueryTableVersionsTrafficUseTableStore: false,

    allowAudioEntryAction: false,
    stringArrayColumns: false,
    // https://github.com/glideapps/glide/issues/32503
    appIsPublicFlagInAppData: false,
    return4xxForInvalidAuthRequest: false,
    inYourFaceUpgradeModalTracker: false,
    inYourFaceUpgradeModalEnabled: false,
    // If this is set to a number, that's the threshold of events above which
    // we will show the in-your-face upgrade modal.  If it's not a number, the
    // modal will not show, even if `inYourFaceUpgradeModalEnabled` is set.
    inYourFaceUpgradeModalThreshold: false,
    shareInsteadOfPublish: false,

    multipartFormDataInFunctions: false,

    frontCustomComponents: false,

    allowInlineAudioTranscript: false,
    // The voice recorder sends a partial audio file to get a partial transcript
    // when it detects silence for this amount of milliseconds.
    // Set to `0` to disable.
    silenceDetectionTimeMS: 1000,

    magicLinkInFirstPublish: false,
    allowAIAgent: false,
};

export type FeatureSettingValue = boolean | number | "experiment";
export type FeatureSettingName = keyof typeof defaultFeatureSettings;
export type FeatureSettings = { readonly [T in FeatureSettingName]: FeatureSettingValue };
