export const docPaths = {
    // APPS
    allComponents: "components",
    button: "button-component",
    buttonBar: "", // DEPRECATED?
    formButton: "forms",
    text: "text-component",
    buyButton: "", // DEPRECATED?
    comments: "comments",
    compoundAction: "", // DEPRECATED?
    dateField: "date-pickers",
    dateTimeField: "date-pickers",
    emailField: "entry-components",
    email: "entry-components",
    favorite: "", // DEPRECATED?
    filePicker: "file-and-image-pickers",
    hint: "hint-component",
    imagePicker: "file-and-image-pickers",
    image: "image-component",
    addRelationColumn: "relations",
    inlineList: "", // DEPRECATED?
    like: "", // DEPRECATED?
    link: "link-component",
    listReference: "relations",
    locationEntry: "location",
    mapImage: "map-component",
    notes: "", // DEPRECATED?
    numberField: "entry-components",
    phoneField: "entry-components",
    phoneNumber: "phone",
    actionText: "", // DEPRECATED?
    progress: "progress-bar",
    rating: "rating-component",
    reaction: "", // DEPRECATED?
    reference: "relations",
    signature: "signature-component",
    simpleTable: "", // DEPRECATED?
    stopwatch: "", // DEPRECATED?
    textField: "entry",
    title: "title-component",
    toggle: "checkbox-and-switch-component",
    compoundComponent: "", // REALLY DEPRECATED
    userProfile: "user-profiles",
    webview: "", // DEPRECATED?
    youtube: "", // DEPRECATED
    choice: "choice-component",
    rowIDConfig: "row-id-column",
    userSpecificColumns: "user-specific-columns",
    protectedColumns: "protected-columns",
    stripeFeesLearnMore: "", // DEPRECATED?
    dataLimits: "updates",
    filters: "conditions-filtering-and-visibility",
    tabVisibility: "security-and-user-data",
    templates: "templates",
    templatePrivateInfo: "templates",
    CAARecords: "custom-domains",
    DNSStep2: "custom-domains",
    DNSStep3: "custom-domains",
    roles: "roles",
    supportCodes: "support",
    scanInstall: "quick-start",
    publishYourApp: "quick-start",
    eventPicker: "", // DEPRECATED?
    separator: "",
    audioPlayer: "audio-component",
    videoPlayer: "video-component",
    markdown: "rich-text-component",
    yesCode: "experimental-code-column",
    yesCodeCaveats: "experimental-code-column#caveats",
    queryColumn: "query-column",

    // PAGES
    pagesHint: "",
    pagesButton: "",
    pagesTitle: "",
    pagesButtons: "", // DEPRECATED?
    pagesBreadcrumbs: "",
    pagesFields: "fields-component",
    pagesRichText: "rich-text-component",
    pagesBigNumbers: "",
    pagesImage: "",
    pagesVideo: "",
    pagesSeparator: "r",
    pagesCardCollection: "collection",
    pagesContactForm: "contact-form",

    // COMMON
    airtable: "airtable",
    bigQuery: "bigquery",
    bigTables: "big-tables",

    // REFRESH
    refresh: "data-refresh",

    // COLUMNS
    mathColumn: "math-column",
    templateColumn: "template-column",
    ifThisThenElseColumn: "if-then-else-column",
    lookupColumn: "lookup-column",
    rollupColumn: "rollup-column",

    style: "intro-to-layout",
    actions: "actions",
    visibility: "visibility-conditions",
    design: "components#design",
    data: "components#data",
    privacy: "users#access-and-privacy",

    dataEditor: "data-editor",
    dataSources: "data-sources",

    troubleshooting: "reference/account/performance-analysis",
    performance: "reference/account/performance-analysis",

    infoSettings: "getting-started/app-settings#name-and-icon",
    appearanceSettings: "getting-started/app-settings#appearance",
};

export type Doc = keyof typeof docPaths;

export function getDocURL(target: Doc): string {
    return "https://glideapps.com/docs/" + docPaths[target];
}
