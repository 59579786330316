import { asMaybeNumber, asString } from "@glide/common-core/dist/js/computation-model/data";
import { isLoadingValue, RollupKind } from "@glide/computation-model-types";
import { ArrayScreenFormat, getColumnProperty, getEnumProperty, getSwitchProperty } from "@glide/app-description";
import type { WireRadialChartComponent, WireRadialChartData } from "@glide/fluent-components/dist/js/base-components";
import {
    ChartingColorScheme,
    DataPlotColorMode,
    RadialChart,
} from "@glide/fluent-components/dist/js/fluent-components";
import type { InlineListComponentDescription } from "@glide/function-utils";
import { WireComponentKind, RadialChartWeights, type WireRowHydrationValueProvider } from "@glide/wire";
import { DefaultMap, mapFilterUndefined } from "@glideapps/ts-necessities";
import { formatValueWithSpecification } from "../format-value";
import { isQueryableColumn } from "../computed-columns";
import { decomposeAggregateRow, getAggregateFromRow } from "../wire/aggregates";
import {
    getFormatForInputColumn,
    inflateComponentEnricher,
    inflateNumberProperty,
    inflateStringProperty,
    makeSimpleWireTableComponentHydratorConstructor,
} from "../wire/utils";
import { makeFluentArrayContentHandler } from "./fluent-array-handler";
import { isExperimentEnabled } from "@glide/common-core/dist/js/use-feature-settings";
import { maybeGetTableColumn } from "@glide/type-schema";

export const radialChartFluentArrayContentHandler = makeFluentArrayContentHandler(
    RadialChart,
    (ib, desc, containingRowIB) => {
        if (containingRowIB === undefined) {
            return undefined;
        }

        const [componentTitleGetter] = inflateStringProperty(containingRowIB, desc.title, true);

        const showLegend = getSwitchProperty(desc.showLegend) ?? false;
        const showValuesInLegend = getSwitchProperty(desc.showValuesInLegend) ?? false;

        const [labelGetter] = inflateStringProperty(ib, desc.labels, true);
        const labelColumnName = getColumnProperty(desc.labels);
        const labelColumn = maybeGetTableColumn(ib.tables.input, labelColumnName);
        const labelFormat = getFormatForInputColumn(ib, labelColumnName);

        const valueIsBound = desc.values !== undefined;
        const [valueGetter] = inflateNumberProperty(ib, desc.values);
        const valueColumnName = getColumnProperty(desc.values);
        const valueColumn = maybeGetTableColumn(ib.tables.input, valueColumnName);

        const lineWeight = getEnumProperty<RadialChartWeights>(desc.lineWeight) ?? RadialChartWeights.Medium;

        const columnName = getColumnProperty(desc.values);
        const format = getFormatForInputColumn(ib, columnName);

        const gbtComputedColumnsAlpha = isExperimentEnabled("gbtComputedColumnsAlpha", ib.adc.userFeatures);
        const gbtDeepLookups = isExperimentEnabled("gbtDeepLookups", ib.adc.userFeatures);

        const componentEnricher = inflateComponentEnricher<WireRadialChartComponent>(
            ib,
            desc as unknown as InlineListComponentDescription
        );

        const undefinedColorGetter = () => undefined;

        const colorMode = getEnumProperty<DataPlotColorMode>(desc.colorMode);
        const [colorGetter] =
            colorMode === DataPlotColorMode.Auto ? [undefinedColorGetter] : inflateStringProperty(ib, desc.color, true);

        const colorScheme = getEnumProperty<ChartingColorScheme>(desc.colorScheme) ?? ChartingColorScheme.Default;

        function makeComponent(containingRowHB: WireRowHydrationValueProvider, chartData: WireRadialChartData[]) {
            const graphTitle = componentTitleGetter(containingRowHB);

            return {
                component: componentEnricher({
                    kind: WireComponentKind.List,
                    format: ArrayScreenFormat.RadialChart,
                    chartData,
                    graphTitle,
                    showLegend,
                    showValuesInLegend,
                    lineWeight,
                    colorScheme,
                }),

                isValid: true,
                hasValue: false,
            };
        }

        return makeSimpleWireTableComponentHydratorConstructor(
            ib,
            (hb, containingRowHB) => {
                if (containingRowHB === undefined) {
                    return undefined;
                }

                const chartData: WireRadialChartData[] = [];

                const valuesForLabels = new DefaultMap<string, number>(() => 0);
                const colorForLabels = new DefaultMap<string, string | undefined>(() => undefined);

                const rows = hb.tableScreenContext.asArray();
                for (const row of rows) {
                    const rhb = hb.makeHydrationBackendForRow(row);

                    const label = labelGetter(rhb) ?? "";
                    const value = valueIsBound ? valueGetter(rhb) ?? 0 : 1;
                    const color = colorGetter(rhb) ?? undefined;
                    valuesForLabels.update(label, v => v + value);
                    colorForLabels.set(label, color);
                }

                for (const [label, value] of valuesForLabels.entries()) {
                    const display = formatValueWithSpecification(format, value) ?? "";
                    chartData.push({ label, value: value, display, color: colorForLabels.get(label) });
                }

                return makeComponent(containingRowHB, chartData);
            },
            (rhb, query, _thb, _searchActive, _pageIndexState, selectedPageSize) => {
                if (
                    labelColumn === undefined ||
                    !isQueryableColumn(ib.adc, ib.tables.input, labelColumn, gbtComputedColumnsAlpha, gbtDeepLookups)
                ) {
                    return undefined;
                }

                query = query.withGroupBy({
                    columns: [labelColumn.name],
                    aggregates: mapFilterUndefined([valueColumn], c => {
                        if (
                            c === undefined ||
                            !isQueryableColumn(ib.adc, ib.tables.input, c, gbtComputedColumnsAlpha, gbtDeepLookups)
                        ) {
                            return undefined;
                        }
                        return {
                            column: c.name,
                            kind: RollupKind.Sum,
                            name: "agg",
                        };
                    }),
                    sort: [{ columnName: labelColumn.name, order: "asc" }],
                    limit: selectedPageSize ?? 1000,
                });
                const table = rhb.resolveQueryAsTable(query);
                if (table === undefined || isLoadingValue(table)) return undefined;

                const chartData: WireRadialChartData[] = [];
                for (const row of table.asMutatingArray()) {
                    const { group, count } = decomposeAggregateRow(row);

                    const value = getAggregateFromRow(row, "agg", asMaybeNumber) ?? count;
                    const display = formatValueWithSpecification(format, value) ?? "";

                    chartData.push({
                        label: formatValueWithSpecification(labelFormat, group) ?? asString(group),
                        value,
                        display,
                        color: colorGetter(rhb) ?? undefined,
                    });
                }

                return makeComponent(rhb, chartData);
            }
        );
    }
);
